import {
  Database,
  STORAGE_SELECTION_MAP_PATH_DELIMITER,
  StorageFileSelectionMap,
  decodeName,
  planStorageLimitsMb,
} from 'common-ts';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from '@/components/ui/menu';
import { ResponseResult, fetchApi } from '@/utils/useApi';
import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import ContentHeader from '../../components/ContentHeader.js';
import DeleteShareModal from './modals/DeleteShareModal';
import EditCollectionModal from './modals/EditCollectionModal.js';
import { ExtendedBucket } from '@/@types/extendedTypes';
import FileUploadButton from './FileUploadButton.js';
import FilesOverview from './FilesOverview.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@chakra-ui/react';
import MoveCollectionModal from './modals/MoveCollectionModal.js';
import ShareCollectionModal from './modals/ShareCollectionModal.js';
import StorageCapacity from './StorageCapacity.js';
import { Tooltip } from '@/components/ui/tooltip';
import { captureException } from '@sentry/react';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faFolder } from '@fortawesome/pro-light-svg-icons';
import { pathStringFromBreadCrumbs } from '../chat/fileSelector/utils.js';
import { toaster } from '@/components/ui/toaster';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '@/store/useBoundStore';
import { StorageSelectionStateHandlers } from '../chat/fileSelector/useStorageSelectionStateHandlers.js';
import { useTranslation } from 'react-i18next';

type CollectionViewProps = {
  className?: string;
  selectedCollection: ExtendedBucket;
  onAfterConfirmShareDeletion?: (unsharedBucketId: string) => void;
  onAfterConfirmBucketDeletion?: (deletedBucketId: string) => void;
  storageSelectionStateHandlers: StorageSelectionStateHandlers;
};

function CollectionView({
  className,
  selectedCollection,
  onAfterConfirmShareDeletion,
  onAfterConfirmBucketDeletion,
  storageSelectionStateHandlers,
}: CollectionViewProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const userInfo = useBoundStore((state) => state.userInfo);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);
  const updateExtendedBuckets = useBoundStore(
    (state) => state.updateExtendedBuckets
  );

  const [selectedFileMap, setSelectedFileMap] =
    useState<StorageFileSelectionMap>({});

  const [editCollectionModalOpen, setEditCollectionModalOpen] = useState(false);
  const [shareCollectionModalOpen, setShareCollectionModalOpen] =
    useState(false);
  const [deleteShareModalOpen, setDeleteShareModalOpen] = useState(false);
  const [moveCollectionModalOpen, setMoveCollectionModalOpen] = useState(false);

  const [usedStorageMb, setUsedStorageMb] = useState<number>(0);
  const [totalStorage, setTotalStorage] = useState<number>(0);

  async function updateUsedStorage() {
    const { data: used_file_storage, error } = await supabase
      .from('used_file_storage')
      .select('total_size_mb, total_size_sharepoint_mb, workspace(total_seats)')
      .eq('workspace_id', workspaceId);

    if (error) {
      captureException(error);
      toaster.create({
        title: error.message,
        type: 'error',
      });
    }
    setTotalStorage(
      (planStorageLimitsMb.get(workspaceLicenseType ?? undefined) || 0) *
        (used_file_storage?.[0]?.workspace?.total_seats ?? 1)
    );
    setUsedStorageMb(
      (used_file_storage?.[0]?.total_size_mb || 0) +
        (used_file_storage?.[0]?.total_size_sharepoint_mb || 0)
    );
  }

  const handleCollectionDeletion = async () => {
    if (!selectedCollection) return;
    const collectionIdToDelete = selectedCollection.id;
    setEditCollectionModalOpen(false);
    onAfterConfirmBucketDeletion?.(collectionIdToDelete);

    const deleteCollectionRes = await fetchApi(
      supabase,
      '/files',
      '/delete_collection',
      { method: 'POST', collectionId: collectionIdToDelete }
    );

    if (!deleteCollectionRes.success) {
      if (
        deleteCollectionRes.status >= 400 &&
        deleteCollectionRes.status < 500
      ) {
        toaster.create({
          title: t('general.unauthorizedError'),
          type: 'error',
        });
      }
      // Don't alert on other error -> we will have to look at db every now and then for buckets that have deleted_at set (bucket_info) but were not deleted
      return;
    }

    toaster.create({
      title: t('fileManagerPanel.collectionDeleteSuccess'),
      type: 'success',
    });
    updateExtendedBuckets();
  };

  async function getSharedBucketId(userId: string | null) {
    if (!selectedCollection || !userId) return;
    onAfterConfirmShareDeletion?.(selectedCollection.id);
    const { data: share, error } = await supabase
      .from('shared_buckets')
      .select('*')
      .eq('bucket_id', selectedCollection.id)
      .eq('user_id', userId)
      .single();

    if (error || !share) {
      captureException(
        error ??
          'Could not get shared bucket id, no error was returned by supabase'
      );
      toaster.create({
        title: error.message,
        type: 'error',
      });
      return;
    }

    if (!share.share_id) return;

    return share.share_id;
  }

  function handleError(
    resp: ResponseResult<{
      success: boolean;
    }>
  ) {
    if (resp.success) {
      updateExtendedBuckets();
    } else {
      captureException(resp.error);
      toaster.create({
        title: resp.error.message,
        type: 'error',
      });
    }
  }

  async function handleShareDeletion() {
    const shareId = await getSharedBucketId(userInfo?.userId ?? null);
    if (!shareId) {
      return;
    }
    const resp = await fetchApi(supabase, '/share', '/unshare_bucket', {
      method: 'POST',
      sharedBucketId: shareId,
      workspaceId,
    });
    handleError(resp);
  }

  useEffect(() => {
    updateUsedStorage();
  }, [workspaceLicenseType, extendedBuckets]);

  async function handleAccessRightsChange(
    newRight: Database['public']['Enums']['access_type'],
    userId: string | null,
    onSuccess?: () => void
  ) {
    const shareId = await getSharedBucketId(userId);
    if (!shareId) {
      return;
    }
    const resp = await fetchApi(supabase, '/share', '/change_access', {
      method: 'POST',
      accessType: newRight,
      sharedBucketId: shareId,
      workspaceId,
    });
    if (resp.success) {
      onSuccess?.();
    }
    handleError(resp);
  }

  function handleSelectAllToggle() {
    if (
      selectedFileMap[selectedCollection.id]?.folders[
        pathStringFromBreadCrumbs({
          breadCrumbs: storageSelectionStateHandlers.breadCrumbs,
          delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
        })
      ]?.selected
    ) {
      storageSelectionStateHandlers.handleDeselectAll(
        storageSelectionStateHandlers.breadCrumbs,
        selectedCollection.id
      );
    } else {
      storageSelectionStateHandlers.handleSelectAllInFolder(
        storageSelectionStateHandlers.breadCrumbs,
        selectedCollection.id
      );
    }
  }

  return (
    <div
      className={twMerge(
        'flex flex-col items-center justify-between md:h-screen',
        className
      )}
    >
      <div className={`flex h-full min-h-0 w-full flex-col gap-6`}>
        {storageSelectionStateHandlers.files && selectedCollection && (
          <div className="relative flex min-h-0 w-full max-w-[1500px] grow flex-col self-center px-8">
            <ContentHeader
              icon={faFolder}
              iconLabel={t('general.dataCollection')}
              headingLabel={decodeName(selectedCollection.display_name)}
              buttons={[
                <MenuRoot>
                  <MenuTrigger asChild>
                    <IconButton
                      borderColor={'maia-border'}
                      size={'xs'}
                      variant={'outline'}
                      colorPalette="blue"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        className="text-maia-text-dark"
                      />
                    </IconButton>
                  </MenuTrigger>
                  <MenuContent>
                    <MenuItem
                      value="TODO"
                      className="text-sm"
                      onClick={() => {
                        setMoveCollectionModalOpen(true);
                      }}
                      asChild
                    >
                      <Button
                        aria-label="TODO"
                        disabled={selectedCollection.access_type !== 'OWN'}
                        className="text-maia-text-dark border-maia-border h-8 font-medium"
                        unstyled
                      >
                        {t(
                          'fileManagerPanel.migrateToWorkspace.migrateToWorkspaceButton'
                        )}
                      </Button>
                    </MenuItem>
                  </MenuContent>
                </MenuRoot>,
                <Tooltip
                  content={
                    selectedCollection.access_type === 'VIEW'
                      ? t('fileManagerPanel.disabledReason')
                      : ''
                  }
                  disabled={selectedCollection.access_type !== 'VIEW'}
                  openDelay={500}
                >
                  <Button
                    className="text-maia-text-dark border-maia-border h-8 font-medium"
                    size={'sm'}
                    variant={'outline'}
                    colorPalette="blue"
                    disabled={selectedCollection.access_type === 'VIEW'}
                    onClick={() => setEditCollectionModalOpen(true)}
                  >
                    {t('fileManagerPanel.editCollectionButton')}
                  </Button>
                </Tooltip>,
                <Button
                  className="text-maia-text-dark border-maia-border h-8 font-medium"
                  size={'sm'}
                  variant={'outline'}
                  colorPalette="blue"
                  onClick={() => setShareCollectionModalOpen(true)}
                >
                  {t('shareCollectionModal.shareButton')}
                </Button>,
                <FileUploadButton
                  uploadPath={pathStringFromBreadCrumbs({
                    breadCrumbs: storageSelectionStateHandlers.breadCrumbs,
                    delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
                  })}
                  buttonProps={{ size: 'sm' }}
                  isDisabled={selectedCollection.access_type === 'VIEW'}
                  collectionId={selectedCollection.id}
                  existingFileNames={storageSelectionStateHandlers.files.map(
                    (file) => file.name
                  )}
                  collectionCustomFileLimit={
                    selectedCollection.custom_file_limit ?? undefined
                  }
                  storageLimitMb={totalStorage}
                  usedStorageMb={usedStorageMb}
                  onFilesStartedUploading={(tempFiles) => {
                    storageSelectionStateHandlers.handleAddUploadingFiles({
                      currentBreadCrumbs:
                        storageSelectionStateHandlers.breadCrumbs,
                      files: tempFiles,
                    });
                    storageSelectionStateHandlers.refetchCurrentFolderContents();
                  }}
                  onFilesFinishedUploading={(tempFiles) => {
                    storageSelectionStateHandlers.handleUploadedFilesFinished({
                      files: tempFiles,
                    });
                    storageSelectionStateHandlers.refetchCurrentFolderContents();
                    updateUsedStorage();
                  }}
                />,
              ]}
            />
            <FilesOverview
              files={storageSelectionStateHandlers.files}
              folders={storageSelectionStateHandlers.folders}
              breadCrumbs={storageSelectionStateHandlers.breadCrumbs}
              selectedCollection={selectedCollection}
              selectedFilesMap={selectedFileMap}
              onFolderClick={(folderName) => {
                storageSelectionStateHandlers.handleGoToFolder({
                  folderId: folderName,
                  name: folderName,
                });
              }}
              onBackClick={() => {
                storageSelectionStateHandlers.handleGoBackInCrumbs(
                  storageSelectionStateHandlers.breadCrumbs.length - 2
                );
              }}
              onBreadCrumbClick={
                storageSelectionStateHandlers.handleGoBackInCrumbs
              }
              onFileCheckboxClick={(fileId) => {
                storageSelectionStateHandlers.handleFileSelectToggle(
                  storageSelectionStateHandlers.breadCrumbs,
                  fileId,
                  selectedCollection.id
                );
              }}
              onFolderCheckboxClick={(folderName) => {
                storageSelectionStateHandlers.handleFolderSelectToggle(
                  storageSelectionStateHandlers.breadCrumbs,
                  folderName,
                  selectedCollection.id
                );
              }}
              onFolderCreateConfirm={(folderName) => {
                storageSelectionStateHandlers.handleCreateTemporaryFolder({
                  name: folderName,
                  currentBreadCrumbs: storageSelectionStateHandlers.breadCrumbs,
                });
                storageSelectionStateHandlers.refetchCurrentFolderContents();
              }}
              onSelectAllClick={handleSelectAllToggle}
              onFileDeletionStarted={() => {
                setSelectedFileMap({});
              }}
              refetchCurrentFolderContents={
                storageSelectionStateHandlers.refetchCurrentFolderContents
              }
            />
          </div>
        )}
      </div>
      <div className="z-[1] w-full border-t border-solid border-gray-200 py-3">
        <StorageCapacity usedMb={usedStorageMb} totalMb={totalStorage} />
      </div>
      <EditCollectionModal
        deleteTooltipLabel={
          selectedCollection?.access_type === 'VIEW'
            ? t('fileManagerPanel.disabledReason')
            : ''
        }
        deleteIsDisabled={selectedCollection?.access_type === 'VIEW'}
        isOpen={editCollectionModalOpen}
        onClose={() => setEditCollectionModalOpen(false)}
        onDelete={handleCollectionDeletion}
        fetchCollections={updateExtendedBuckets}
        selectedCollectionName={selectedCollection?.display_name}
        selectedCollectionId={selectedCollection?.id}
      />
      <DeleteShareModal
        isOpen={deleteShareModalOpen}
        onClose={() => setDeleteShareModalOpen(false)}
        onConfirm={handleShareDeletion}
      />
      <ShareCollectionModal
        onAccessChange={handleAccessRightsChange}
        isOpen={shareCollectionModalOpen}
        onClose={() => setShareCollectionModalOpen(false)}
        selectedCollectionId={selectedCollection.id}
        isPublicForWorkspace={selectedCollection.is_public_for_workspace}
      />
      <MoveCollectionModal
        collectionId={selectedCollection.id}
        collectionName={decodeName(selectedCollection.display_name)}
        isOpen={moveCollectionModalOpen}
        onClose={() => setMoveCollectionModalOpen(false)}
      />
    </div>
  );
}

export default CollectionView;
