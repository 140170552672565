// External libraries
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CollectionView from './CollectionView.js';
import CollectionsOverview from './CollectionsOverview.js';
// Hooks
import CreateCollectionModal from './modals/CreateCollectionModal.js';
import { ExtendedBucket } from '../../@types/extendedTypes.js';
import { FREE_COLLECTION_LIMIT } from '../../utils/userPlan.js';
import MaiaAlertDialogWithUpgradeButton from '../../components/MaiaAlertDialogWithUpgradeButton.js';
// Internal components
import MaiaLayout from '../../components/MaiaLayout.js';
import MaiaMenuPanel from '../../components/menu/MaiaMenuPanel.js';
import NoCollection from './NoCollection.js';
import { ROUTES } from 'common-ts';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import useStorageSelectionStateHandlers from '@/pages/chat/fileSelector/useStorageSelectionStateHandlers';

export const FILE_MANAGER_ROUTE_COLLECTION_ID = 'collectionId';

export default function FileManager() {
  const { t } = useTranslation();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);
  const updateExtendedBuckets = useBoundStore(
    (state) => state.updateExtendedBuckets
  );
  const lastVisitedCollection = useBoundStore(
    (state) => state.lastVisitedCollection
  );
  const updateLastVisitedCollection = useBoundStore(
    (state) => state.updateLastVisitedCollection
  );

  const { [FILE_MANAGER_ROUTE_COLLECTION_ID]: collectionId } = useParams();
  const navigate = useNavigate();

  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const [selectedCollection, setSelectedCollection] = useState<
    ExtendedBucket | undefined
  >();

  const collectionCreatedRecently = useRef('');
  const [createCollectionModalOpen, setCreateCollectionModalOpen] =
    useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  const [collectionIdsBeingDeleted, setCollectionIdsBeingDeleted] = useState<
    string[]
  >([]);

  /**
   * Handlers for selecting and deselecting collections, folders and files in the MAIA storage.
   */
  const storageSelectionStateHandlers = useStorageSelectionStateHandlers(
    () => true,
    () => true,
    {}
  );

  useEffect(() => {
    let foundSelectedCollection = extendedBuckets.find(
      (collection) => collection.id === collectionId
    );

    if (collectionCreatedRecently.current) {
      foundSelectedCollection = extendedBuckets.find(
        (collection) => collection.id === collectionCreatedRecently.current
      );
      collectionCreatedRecently.current = '';
    }

    if (!foundSelectedCollection) {
      // if there exists a collection that has been previously visited, redirect to that collection
      // otherwise redirect to the first collection in the list
      foundSelectedCollection = extendedBuckets.find(
        (collection) => collection.id === lastVisitedCollection
      );
      handleCollectionSelect(foundSelectedCollection ?? extendedBuckets[0]);
    } else {
      handleCollectionSelect(foundSelectedCollection);
    }
  }, [extendedBuckets]);

  useEffect(() => {
    if (!collectionId) {
      const foundSelectedCollection = extendedBuckets.find(
        (collection) => collection.id === lastVisitedCollection
      );
      handleCollectionSelect(foundSelectedCollection ?? extendedBuckets[0]);
      return;
    }

    const foundSelectedCollection = extendedBuckets.find(
      (collection) => collection.id === collectionId
    );

    setSelectedCollection(foundSelectedCollection);
  }, [collectionId]);

  function handleCollectionSelect(collection: ExtendedBucket | undefined) {
    // In case newly selected collection is already selected, update with new data from extendedBuckets
    if (collection && collection.id === selectedCollection?.id) {
      const foundSelectedCollection = extendedBuckets.find(
        (coll) => coll.id === collection.id
      );

      setSelectedCollection(foundSelectedCollection);
      return;
    }
    updateLastVisitedCollection(collection?.id);
    storageSelectionStateHandlers.updateCurrentCollection({
      id: collection?.id ?? '',
      name: collection?.name ?? '',
    });

    navigate(
      ROUTES.HOME.FILE_MANAGER.buildPath({
        workspaceId,
        collectionId: collection?.id,
      })
    );
  }

  return (
    <>
      <MaiaLayout
        sidePanelContent={
          <MaiaMenuPanel selectedNavigation="FILE_MANAGER">
            <CollectionsOverview
              collections={extendedBuckets.filter(
                (collection) =>
                  !collectionIdsBeingDeleted.includes(collection.id)
              )}
              selectedCollection={selectedCollection}
              onCollectionSelect={(collection) => {
                handleCollectionSelect(collection);
                setSidePanelOpen(false);
              }}
              onCreateDataCollectionClick={() => {
                if (
                  workspaceLicenseType === 'FREE' &&
                  extendedBuckets.filter((bucket) => !bucket.is_shared_to_user)
                    .length >= FREE_COLLECTION_LIMIT
                ) {
                  setUpgradeModalOpen(true);
                } else {
                  setCreateCollectionModalOpen(true);
                }
              }}
            />
          </MaiaMenuPanel>
        }
        mainPanelContent={
          <div className="flex h-full w-full justify-center bg-white">
            {extendedBuckets.length && selectedCollection ? (
              <CollectionView
                key={selectedCollection.id}
                className="w-full"
                selectedCollection={selectedCollection}
                onAfterConfirmBucketDeletion={(deletedBucketId) => {
                  handleCollectionSelect(
                    extendedBuckets.find(
                      (collection) => collection.id !== deletedBucketId
                    )
                  );
                  setCollectionIdsBeingDeleted((prev) => [
                    ...prev,
                    deletedBucketId,
                  ]);
                }}
                storageSelectionStateHandlers={storageSelectionStateHandlers}
              />
            ) : (
              <NoCollection
                className="h-full w-full max-w-2xl"
                onAddDataCollectionClick={() =>
                  setCreateCollectionModalOpen(true)
                }
              />
            )}
          </div>
        }
        sidePanelOpenMobile={sidePanelOpen}
        onCloseSidePanelMobile={() => setSidePanelOpen(false)}
        onOpenSidePanelMobile={() => setSidePanelOpen(true)}
      />
      <CreateCollectionModal
        isOpen={createCollectionModalOpen}
        onClose={() => setCreateCollectionModalOpen(false)}
        onCollectionCreated={(collectionId) => {
          updateExtendedBuckets();
          collectionCreatedRecently.current = collectionId;
        }}
      />
      <MaiaAlertDialogWithUpgradeButton
        isOpen={upgradeModalOpen}
        onClose={() => setUpgradeModalOpen(false)}
        AlertDialogHeaderText={t('fileManagerPanel.collectionLimitReached')}
        AlertDialogBodyText={t('fileManagerPanel.collectionLimitReachedBody')}
      />
    </>
  );
}
