import { useState, useEffect, useCallback } from 'react';
import {
  AvailableModelEnum,
  ExperimentalModelEnum,
  isModelAvailableForPlan,
} from 'common-ts';
import { UserPlan } from './userPlan';

const gptKey = '05aec42a-62fa-4710-aaf9-613479c5923e';

/**
 * validates model for a given userPlan and returns it if valid / a default one if not
 * always returns a valid model for a user
 *
 * @param userPlan the plan of a user to check against
 * @param model the model name to validate
 * @returns a valid model for the user
 */
function validateModelForPlan(
  userPlan: UserPlan | undefined,
  model: string | null
): AvailableModelEnum | ExperimentalModelEnum {
  // Default model if none is set.
  if (!model) {
    return AvailableModelEnum.GPT_4_O;
  }

  if (
    !isModelAvailableForPlan(
      userPlan ?? 'FREE',
      model,
      import.meta.env.VITE_MAIA_STAGE !== 'prod' ||
        import.meta.env.VITE_MAIA_STAGE !== 'release'
    )
  ) {
    // Default model if chosen model is not valid
    return AvailableModelEnum.GPT_4_O_MINI;
  } else {
    return model as AvailableModelEnum | ExperimentalModelEnum;
  }
}

export const useLlmModelStorage = (userPlan: UserPlan | undefined) => {
  const [model, setModel] = useState<
    AvailableModelEnum | ExperimentalModelEnum
  >(validateModelForPlan(userPlan, localStorage.getItem(gptKey)));

  const setLlmModel = useCallback(
    (newModel: AvailableModelEnum | ExperimentalModelEnum) => {
      const validNewModel = validateModelForPlan(userPlan, newModel);
      localStorage.setItem(gptKey, validNewModel);
      setModel(validNewModel);
    },
    []
  );

  // Handle storage changes from other tabs
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === gptKey) {
        setModel(validateModelForPlan(userPlan, event.newValue));
      }
    };

    // Add event listener
    window.addEventListener('storage', handleStorageChange);

    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    setModel(validateModelForPlan(userPlan, model));
  }, [userPlan]);

  return { model, setLlmModel };
};
