import { useEffect, useState } from 'react';

import {
  AvailableModelEnum,
  ExperimentalModelEnum,
  getAvailableModelsForPlan,
} from 'common-ts';
import { useBoundStore } from '@/store/useBoundStore';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from './ui/select.js';
import { createListCollection } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useLlmModelStorage } from '@/utils/useLlmModelStorage';

type ModelOption = {
  label: string;
  value: AvailableModelEnum | ExperimentalModelEnum;
};

const supportedModelsDisplayNameMap = new Map<
  AvailableModelEnum | ExperimentalModelEnum,
  string
>([
  // keeping the naming convention consistent with ChatGpt
  [AvailableModelEnum.GPT_4_O, 'GPT-4o'],
  [AvailableModelEnum.GPT_4_O_MINI, 'GPT-4o mini'],
  [ExperimentalModelEnum.MISTRAL_LARGE_2407, 'Mistral-large'],
  [ExperimentalModelEnum.META_LLAMA_3_3_70B_INSTRUCT, 'Llama 3.3'],
]);

type ChangeLlmProps = {
  onChange?: () => void;
};

export function ChangeLlm({ onChange }: ChangeLlmProps) {
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );

  const { model, setLlmModel } = useLlmModelStorage(
    workspaceLicenseType ?? undefined
  );

  const [modelOptions, setModelOptions] = useState(
    createListCollection<ModelOption>({ items: [] })
  );

  const updateAvailableModels = () => {
    const availableModelsForPlan = getAvailableModelsForPlan(
      workspaceLicenseType ?? 'FREE',
      import.meta.env.VITE_MAIA_STAGE !== 'prod' ||
        import.meta.env.VITE_MAIA_STAGE !== 'release'
    );

    const supportedModels = createListCollection<ModelOption>({
      items: Array.from(supportedModelsDisplayNameMap.entries())
        .filter(([modelEnum, _]) => availableModelsForPlan.includes(modelEnum))
        .map(([modelEnum, modelName]) => {
          return { label: modelName, value: modelEnum };
        }),
    });

    setModelOptions(supportedModels);
  };

  useEffect(() => {
    updateAvailableModels();
  }, [model, workspaceLicenseType]);

  function persistGpt(model: AvailableModelEnum | ExperimentalModelEnum) {
    setLlmModel(model);
    onChange?.();
  }

  return (
    // for some reason, select is really adamant about default styles. Probably a bug!
    <SelectRoot
      collection={modelOptions}
      size={'sm'}
      onValueChange={({ value }) =>
        persistGpt(value[0] as AvailableModelEnum | ExperimentalModelEnum)
      }
      variant={'subtle'}
      value={[model]}
      unstyled
    >
      <SelectTrigger className="flex w-max min-w-36 items-center justify-center">
        <SelectValueText />
        <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
      </SelectTrigger>
      <SelectContent className="border-maia-border flex w-max flex-col gap-y-0.5 rounded border border-solid bg-white px-2 py-2 shadow">
        {modelOptions.items.map((supportedModel) => (
          <SelectItem
            item={supportedModel}
            key={supportedModel.value}
            className={`hover:bg-maia-blue-100 flex w-full cursor-pointer items-center justify-between rounded px-2 py-1 ${model === supportedModel.value ? 'bg-gray-100' : ''}`}
          >
            <span>{supportedModel.label}</span>
            {model === supportedModel.value && (
              <FontAwesomeIcon icon={faCheck} className="ml-4" />
            )}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
}
